<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
<!--                        {{assessment.name}}-->
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.assessments.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-select :items="companies" v-model="assessment.company_id"  item-text="name" item-value="id" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Score" type="number" v-model="assessment.score"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="assessment.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="handleSaveAssessment">Update</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Assessment from "../../../models/Assessment";
    import Company from "../../../models/Company";

    export default {
        name: "users-show",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                assessment: {},
                companies: [],
            }
        },
        async mounted() {
            this.companies = await Company.get()
            const assessmentId = this.$route.params.id
            if (assessmentId) {
                this.assessment = await Assessment.find(assessmentId)
            }
        },
        methods: {
            async handleSaveAssessment() {
                let assessment = new Assessment(this.assessment)
                assessment = await  assessment.save()
                this.assessment = assessment
                await this.$router.push({name: 'admin.assessments.show', params: {id: this.assessment.id}})
            }
        }
    }
</script>

<style scoped>

</style>
